@import
"src/main/scss/mixins/media-query",
"src/main/scss/mixins/state-indicator",
"src/main/scss/functions/find-color-invert";

.alert {
  position: relative;
  background: #fff;
  color: #a5a6af;
  background: #36384a;
  padding: var(--space-md);
  font-size: var(--text-sm);

  p {
    margin: 0;

    &:not(:last-child){
      margin-bottom: var(--space-md);
    }
  }

  .ico {
    line-height: var(--body-line-height);
  }

  .ico,
  a,
  button {
    color: inherit;
  }

  &__head {
    display: flex;
    align-items: center;
  }

  &__media {
    margin-right: var(--space-md);
  }

  &__headline {
    flex-grow: 1;
    font-size: var(--text-md);
    font-weight: var(--type-weight-heading);
  }

  &__actions {
    margin-left: var(--space-md);
  }

  &__content {
  }


  // &__content {
  //   display: flex;
  //   flex-direction: column;
  //   flex-wrap: wrap;
  //   justify-content: center;
  //   flex-grow: 1;
  //   font-size: var(--text-sm);
  //   line-height: var(--body-line-height);
  // }
  //
  // &__footer {
  //   border-top: 1px solid rgba(0,0,0,.1);
  //   padding-top: var(--space-md);
  //   margin-top: var(--space-lg);
  // }



  &__body {
    margin-top: var(--space-md);
  }

  &__close {
    // position: absolute;
    // top: 0;
    // right: 0;
    // color: inherit;
    // padding: var(--space-md) var(--space-lg) var(--space-md) var(--space-md);
    // font-size: var(--text-base-size);
    // line-height: 1;
  }

  &.is {
    &--info {
      background: lighten(#3a87ad, 38%);
      color: #3a87ad;
    }

    &--valid {
      background: lighten(#468847, 38%);
      color: #468847;
    }

    &--error {
      background: lighten(#b94a48, 38%);
      color: #b94a48;
    }

    &--warning {
      background: lighten(#c09853, 38%);
      color: #c09853;
    }

    &--hidden {
      display: none;
    }
  }
}
